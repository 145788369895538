import { render, staticRenderFns } from "./NewProduct.vue?vue&type=template&id=a1addaac&scoped=true&"
import script from "./NewProduct.vue?vue&type=script&lang=js&"
export * from "./NewProduct.vue?vue&type=script&lang=js&"
import style0 from "./NewProduct.vue?vue&type=style&index=0&id=a1addaac&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a1addaac",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCardText,VRadio,VRadioGroup,VTextarea})
